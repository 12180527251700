@font-face {
  font-family: 'Visby';
  src: local("Visby"),
    url("./fonts/VisbyRegular.otf") format("truetype");
}

@font-face {
  font-family: 'Visby XB';
  src: local("Visby XB"),
    url("./fonts/VisbyExtrabold.otf") format("truetype");
}

@font-face {
  font-family: 'Archivo';
  src: url("./fonts/Archivo-Regular.ttf");
}

:root {
  --primary-color: #EF7056;
  --secondary-color: #0B2325;
}

html,
body,
#root {
  font-family: Visby;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #DADADA;
  border-radius: 10px;
} */

::-webkit-scrollbar-thumb {
  background: #DADADA;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999999;
}