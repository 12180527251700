@font-face {
    font-family: visby;
    src: url(../../fonts/VisbyRegular.otf);
}

@font-face {
    font-family: visby-medium;
    src: url(../../fonts/VisbyMedium.otf);
}

@font-face {
    font-family: visby-bold;
    src: url(../../fonts/VisbyBold.otf);
}

* {
    /* box-sizing: border-box; */
    margin: 0;
    padding: 0;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'visby', Arial, Helvetica, sans-serif;
    font-size: 16px;
    /* line-height: 24px; */
    color: #161616;
    font-weight: normal;
    background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

.container {
    width: 100%;
    margin: 0 auto;
}

/*.body-content {
    padding: 40px;
}*/

.search-cont {
    position: relative;
}

.search-cont img {
    position: absolute;
    top: 10px;
    left: 16px;
}

.search-inpt {
    padding: 10px 16px 10px 45px;
    border-radius: 4px;
    background: #F6F6F6;
    border: 0;
    color: #8F8F91;
    font-size: 14px;
}

.search-inpt:focus {
    outline: solid 2px #ccc;
}

.btn-green {
    padding: 12px;
    border-radius: 7px;
    border: 3.5px solid #C6DDD7;
    background: var(--primary-color);
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    margin-left: 70px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.btn-green img {
    vertical-align: middle;
    padding-right: 10px;
}

.btn-green:hover {
    background: #62AC98;
}

.body-head {
    color: #2A2A2F;
    font-size: 24px;
    font-weight: normal;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    line-height: 22.738px;
    letter-spacing: -0.068px;
}

.b-head-tag {
    color: var(--primary-color);
    font-family: 'visby', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 13.264px;
    padding-top: 12px;
    margin-bottom: 0;
}

.title-offer {
    width: auto;
    float: left;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clear-fix {
    clear: both;
}

.body-head-cont {
    padding-bottom: 24px;
}

.hotel-dpdwn {
    padding-top: 24px;
}

.hotel-dpdwn .btn {
    border-radius: 5px;
    border: 1px solid #999;
    background: #FFF;
    padding: 13px;
    font-size: 16px;
    font-family: 'visby-medium', Arial, Helvetica, sans-serif;
    color: #565656;
}

.btn-avtr {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    vertical-align: middle;
}

.hotel-dpdwn .btn:hover,
.hotel-dpdwn .btn.show,
.hotel-dpdwn .btn:first-child:active,
:not(.btn-check)+.hotel-dpdwn .btn:active {
    background: #f8f9fa;
}

.table-cont {
    margin-top: 24px
}

.table-br-cont {
    border-radius: 8px;
    border: 1px solid #DADADA;
    background: #FFF;
}

.table-responsive
{
    overflow: auto;
    width: 100%;
}

.table-br-cont table {
    margin-bottom: 0;
    border-color: #E9E9E9;
    width: 100%;
}

.table-br-cont .table>thead {
    border-bottom: 0.677px solid #E9E9E9;
    background: #F6F6F6;
}

.table-br-cont .table>thead th {
    font-size: 12px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    color: #626266;
    padding: 5px 20px;
    font-weight: normal;
}

.table-br-cont .table>tbody td {
    font-size: 12px;
    font-family: 'visby', Arial, Helvetica, sans-serif;
    color: #626266;
    vertical-align: top;
}

.table-br-cont .table> :not(caption)>*>* {
    padding: 15px 20px;
}

.tbl-offer {
    color: #161616;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 13.264px;
    letter-spacing: -0.068px;
    margin-bottom: 24px;
}

.tbl-action {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}

.tbl-action li {
    padding: 0;
    margin: 0;
}

.tbl-action li a {
    color: #474747;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
}

.tbl-action li a:hover {
    text-decoration: underline;
}

.tbl-action li a img {
    padding-right: 6px;
    font-size: 20px;
}

.rooms-titl {
    color: #161616;
    font-size: 14px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    line-height: 20px;
    letter-spacing: -0.068px;
    margin-bottom: 0;
}

.rooms-p {
    color: #626266;
    font-size: 12px;
    line-height: 13.264px;
    letter-spacing: -0.068px;
}

.disc-titl {
    color: #161616;
    font-size: 16px;
    line-height: 13.264px;
    letter-spacing: -0.068px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
}

.disc-titl img {
    padding-right: 6px;
    vertical-align: middle;
}

.device-bdg {
    padding: 3px 4px;
    color: #585757;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    background: #E8E8E8;
    border-radius: 4px;
    margin-bottom: 12px;
}

.cat-badge {
    padding: 3px 4px;
    color: #FF9F2D;
    font-size: 12px;
    line-height: normal;
    background: #FFF5E9;
    border-radius: 4px;
}

.bus-badge {
    padding: 3px 4px;
    color: #0085FF;
    font-size: 12px;
    line-height: normal;
    background: #E6F3FF;
    border-radius: 4px;
}

.date-title {
    color: #161616;
    font-size: 14px;
    line-height: 13.264px;
    letter-spacing: -0.068px;
    margin-bottom: 5px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
}

.information {
    padding: 8px;
    border-radius: 4px;
    background: rgba(66, 140, 120, 0.10);
    list-style: none;
    margin: 0;
}

.information li {
    list-style: none;
    margin: 0;
    padding-bottom: 5px;
    color: #161616;
    font-size: 14px;
    line-height: 13.264px;
    letter-spacing: -0.068px;
    /* font-weight: bold; */
}

.information li:last-child {
    padding-bottom: 0;
}

.table-btn-head {
    width: 100%;
    margin-bottom: 16px;
}

.nav-link {
    font-size: 14px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #fff;
    background: none;
    cursor: pointer;
    padding-bottom: 6px;
    font-family: 'visby-medium', Arial, Helvetica, sans-serif;
    color: #929292;
    margin-right: 32px;
}

.nav-link:last-child {
    margin-right: 0;
}

.nav-link.active,
.nav-link:hover {
    color: #161616;
    border-bottom: solid 2px #161616;
}

.nav-link .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #DD172C;
    border-radius: 10px;
    margin-left: 6px;
}

.information li strong {
    font-family: 'visby-medium', Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.table-footer {
    margin-top: 28px;
}

.numb-dp {
    padding: 5px 7px 5px 9px;
    font-size: 12px;
    background: #fff;
    border: 0;
    border: solid 1px #999;
    border-radius: 5px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    margin-right: 5px;
}

.table-footer label {
    color: #2A2A2F;
    font-size: 13px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    padding-left: 5px;
}

.btn-arrow {
    padding: 9px 10px 6px;
    border-radius: 5px;
    border: 1px solid #565656;
    background: #FFF;
    cursor: pointer;

    .btn-arrow:first-child {
        margin: 0;
    }

    ;
    margin-left:5px;
}

.img-dropdown {
    background-image: url('../../assets/icons/Avatar.svg');
}

.add-button {
    background-image: url('../../assets/icons/add.svg');
}

.input-cont>.dtpicker_offer>div {
    float: left;
}

/*new footer section*/
.pages-list {
    list-style: none;
    padding: 0;
    margin: 0;
    left: 40%;
    position: relative;
    display: inline-block;
}

.pages-list li {
    list-style: none;
    padding: 5px 8px;
    margin: 0 5px;
    display: inline-block;
    color: #565656;
    font-size: 14px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.pages-list li.active {
    background: var(--primary-color);
    color: #fff;
    border-radius: 5px;
}

.pages-list li:first-child,
.pages-list li:last-child {
    margin: 0;
}



.number-cont {
    position: relative;
    width: 45px;
    float: left;
    margin-right: 5px;
}

.arrow-up-btn {
    position: absolute;
    top: 8px;
    right: 5px;
    cursor: pointer;
}

.arrow-down-btn {
    position: absolute;
    bottom: 8px;
    right: 5px;
    cursor: pointer;
}

/* .nexted-form > .datepicker-float-offer
{
    float: left;
}
.nexted-form > .datepicker-float-offer+div
{
    float: left;
}

.nexted-form > .datepicker-to-float-offer
{
    float: left;
}
.nexted-form > .datepicker-to-float-offer+div
{
    float: left;
} */

/*Add offers*/
.body-head img {
    padding-right: 8px;
}

.body-sub-head {
    padding: 20px 24px;
    color: var(--primary-color);
    font-family: 'visby-medium', Arial, Helvetica, sans-serif;
    font-size: 22px;
}

.forms {
    padding: 0;
    margin: 0;
    list-style: none;
    border: solid 1px #DADADA;
    border-radius: 5px;
}

.forms li {
    padding: 26px 24px;
    margin: 0;
    list-style: none;
    border-bottom: solid 1px #DADADA;
    color: #2A2A2F;
    display: table;
    width: 100%;
    /*need to be changed*/
}

.forms li:last-child {
    border: 0;
}

.form-label {
    display: table-cell;
    width: 260px;
    color: #2A2A2F;
    font-size: 16px;
    font-family: 'visby', Arial, Helvetica, sans-serif;
    font-weight: bold;
    vertical-align: middle;
}

.input-cont {
    display: table-cell;
    vertical-align: middle;
}

.form-control {
    border-radius: 4px;
    border: 1px solid #DADADA;
    background: #FFF;
    width: 400px;
    padding: 12px 16px 12px 16px;
    color: #555555;
    font-size: 14px;
}

.form-control:focus {
    outline: 1px solid #626266;
}

.vertical-middle {
    vertical-align: middle;
}

.input-cont h3 {
    color: #2A2A2F;
    font-size: 16px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
}

.nexted-form {
    display: inline-block;
    margin-right: 10px;
}

.nexted-form:last-child {
    margin-right: 0;
}

.nexted-form label {
    display: block;
    color: #161616;
    font-size: 14px;
    font-family: 'visby-bold', Arial, Helvetica, sans-serif;
    margin-bottom: 6px;
}

.nexted-form .form-control {
    border-radius: 4px;
    border: 1px solid #DADADA;
    background: #FFF;
    width: 150px;
    padding: 12px 16px 12px 16px;
    color: #555555;
    font-size: 14px;
    margin-right: 10px;
}

.nexted-form .form-control:last-child {
    margin: 0;
}

.nexted-form .form-control:focus {
    outline: 1px solid #626266;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
    vertical-align: middle;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 0;
    bottom: -1px;
    background-color: var(--primary-color);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #C8E5D7;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.check-container {
    cursor: pointer;
    font-family: 'visby-medium', Arial, Helvetica, sans-serif;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 5px;
    border: 1px solid #DADADA;
    background: rgba(66, 140, 120, 0.10);
    color: #555;
    font-size: 14px;
    padding: 14px 14px 14px 43px;
    width: 300px;
    margin-bottom: 12px;
}

.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 12px;
    left: 13px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: solid 2px var(--primary-color);
    border-radius: 3px;
}

.container:hover input~.checkmark {
    background-color: #fff;
}

.check-container input:checked~.checkmark {
    background-color: var(--primary-color);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-container input:checked~.checkmark:after {
    display: block;
}

.check-container .checkmark:after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.body-content * {
    box-sizing: border-box;
}
